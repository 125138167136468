import CircleActiveMenuSVG from './CircleActiveMenuSVG.svg';
import CircleNoActiveMenuSVG from './CircleNoActiveMenuSVG.svg';
import CircleUserSVG from './CircleUser.svg';
import Info1SVG from './Info1.svg';
import MainSVG from './Main.svg';
import Menu1SVG from './Menu1.svg';
import Menu2SVG from './Menu2.svg';
import PackageSVG from './Package.svg';
import PlusSVG from './PlusSVG.svg';
import SettingsSVG from './Settings.svg';
import SignOutSVG from './SignOut.svg';
import SubscriptionSVG from './Subscription.svg';
import SupportSVG from './Support.svg';
import WhatsNewsSVG from './WhatsNews.svg';

export {
  Info1SVG,
  MainSVG,
  Menu1SVG,
  Menu2SVG,
  SettingsSVG,
  SubscriptionSVG,
  SupportSVG,
  WhatsNewsSVG,
  CircleUserSVG,
  CircleActiveMenuSVG,
  CircleNoActiveMenuSVG,
  PackageSVG,
  SignOutSVG,
  PlusSVG,
};
