import { useTranslations } from 'next-intl';
import { FC, memo } from 'react';
import cls from './Sidebar.module.css';
import { SidebarItem } from '../SidebarItem/SidebarItem';
import { LogoIconLink } from '@/features/LogoIconLink';
import {
  MainSVG,
  PackageSVG,
  SettingsSVG,
  SupportSVG,
} from '@/shared/assets/svg/Main/Menu';
import {
  getRoutePersonAccMain,
  getRoutePersonAccSettings,
  getRoutePersonAccSubscribes,
  getRoutePersonAccSupport,
} from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { VStack } from '@/shared/ui/Stack';
// eslint-disable-next-line path-checher-ulbi-example/layer-imports

export interface ISidebarProps {
  className?: string;
}

export const Sidebar: FC<ISidebarProps> = memo(props => {
  const { className } = props;
  const t = useTranslations();

  const sidebarItemsList = [
    { Icon: MainSVG, path: getRoutePersonAccMain(), text: t('Home') },
    {
      Icon: PackageSVG,
      path: getRoutePersonAccSubscribes(),
      text: t('Packages'),
    },
    {
      Icon: SettingsSVG,
      path: getRoutePersonAccSettings(),
      text: t('Settings'),
    },
    {
      Icon: SupportSVG,
      path: getRoutePersonAccSupport(),
      text: t('Support'),
    },
  ];

  return (
    <aside
      data-testid='sidebar'
      className={classNames(cls.sidebar, {}, [className])}
    >
      <LogoIconLink />
      <VStack role='navigation' gap='x-s'>
        {sidebarItemsList.map(item => (
          <SidebarItem key={item.path} item={item} />
        ))}
      </VStack>
    </aside>
  );
});
