import { signOut } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { FC, memo, useCallback, useMemo } from 'react';
import cls from './mobileMenu.module.css';
import { SelectLanguage } from '@/features/SelectLanguage';
import { SignOutSVG } from '@/shared/assets/svg/Main/Menu';
import { classNames } from '@/shared/lib/classNames/classNames';
import { VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';
// eslint-disable-next-line path-checher-ulbi-example/layer-imports
import { SidebarItem, SidebarItemType } from '@/widgets/Sidebar';

interface IMobileMenuProps {
  className?: string;
  onClickItem?: (id: string) => void;
  links: SidebarItemType[];
  type: 'link' | 'text';
}

export const MobileMenu: FC<IMobileMenuProps> = memo(props => {
  const { className, onClickItem, links, type } = props;

  const t = useTranslations();

  const logOut = (id: string) => signOut();

  const isLink = useMemo(() => type === 'link', [type]);

  const gap = useMemo(() => (isLink ? 'x-s' : 'x-l'), [isLink]);
  const classNameGeneral = useMemo(
    () =>
      classNames(cls['menu-container'], { [cls.link]: isLink }, [className]),
    [className, isLink],
  );

  const content = useCallback(
    (item: SidebarItemType) =>
      isLink ? (
        <SidebarItem key={item?.id} item={item} />
      ) : (
        <Text
          key={item?.id}
          id={`header-${item?.id}`}
          className={cls.text}
          wrap='nowrap'
          variant='button-default'
          onClick={() => item?.id && onClickItem?.(item?.id)}
        >
          {item.text}
        </Text>
      ),
    [isLink, onClickItem],
  );

  const logout = useMemo(
    () => (
      <SidebarItem
        item={{
          Icon: SignOutSVG,
          id: 'link-5',
          onClickItem: logOut,
          text: t('LogOut'),
        }}
      />
    ),
    [t],
  );

  return (
    <VStack max gap={gap} align='start' className={classNameGeneral}>
      {links.map(item => content(item))}
      <SelectLanguage
        fullWidth={isLink}
        className={classNames('', { [cls.lang]: isLink }, [])}
      />
      {!!isLink && logout}
    </VStack>
  );
});
