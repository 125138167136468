import { memo } from 'react';
import { JeroldSVG } from '@/shared/assets/svg/Main';
import { getRouteWelcome } from '@/shared/const/router';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { Icon } from '@/shared/ui/Icon';

export const LogoIconLink = memo(() => {
  const { push } = useAppNavigation();

  const redirectToMain = () =>
    push({ paramsToDelete: ['productId', 'partner'], path: getRouteWelcome() });

  return (
    <Icon
      clickable
      Svg={JeroldSVG}
      width={80}
      height={20}
      color='#fff'
      onClick={redirectToMain}
    />
  );
});
