import { FC, memo } from 'react';
import cls from './sidebarItem.module.css';
import { ISidebarItemProps } from '../../model/types/sidebar';
import { classNames } from '@/shared/lib/classNames/classNames';
import { Icon } from '@/shared/ui/Icon';
import { Links } from '@/shared/ui/Links/Links';
import { Text } from '@/shared/ui/Text';

export const SidebarItem: FC<ISidebarItemProps> = memo(props => {
  const { className, collapsed, item } = props;

  const onClickButton = () => {
    if (item?.id && item?.onClickItem) item.onClickItem(item.id);
  };

  return (
    <Links
      href={item?.path || ''}
      prefetch={false}
      activeClassName={cls.active}
      className={classNames(cls['sidebar-item'], {}, [className])}
      onClick={onClickButton}
    >
      <Icon width={24} height={24} Svg={item.Icon} />
      <Text wrap='nowrap' variant='body-l-medium'>
        {item.text}
      </Text>
    </Links>
  );
});
