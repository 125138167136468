import { useLocale } from 'next-intl';
import { FC, memo, useCallback, useMemo } from 'react';
import cls from './selectLanguage.module.css';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { useAppPathname } from '@/shared/lib/router/navigation';
import { Select } from '@/shared/ui/Popovers';
import {
  ISelectProps,
  SelectItem,
} from '@/shared/ui/Popovers/ui/Select/Select';

interface ISelectLanguageProps
  extends Omit<ISelectProps, 'items' | 'onChange'> {
  className?: string;
}

export const SelectLanguage: FC<ISelectLanguageProps> = memo(props => {
  const { className, fullWidth, ...otherProps } = props;

  const language = useLocale();
  const pathname = useAppPathname();
  const { replace } = useAppNavigation();

  const items: SelectItem[] = [
    { content: 'en', value: 'en', variant: 'body-l-uppercase' },
    { content: 'ru', value: 'ru', variant: 'body-l-uppercase' },
  ];

  const onChangeLanguage = useCallback(
    (value: string) => {
      replace({
        options: { locale: value === 'ru' || value === 'ru-RU' ? 'ru' : 'en' },
        path: pathname,
      });
      localStorage.setItem(
        'i18nextLng',
        value === 'ru' || value === 'ru-RU' ? 'ru' : 'en',
      );
    },
    [pathname, replace],
  );

  const classSelect = useMemo(() => (fullWidth ? '' : cls.select), [fullWidth]);

  const currentValue = useMemo(
    () => (language === 'ru' || language === 'ru-RU' ? 'ru' : 'en'),
    [language],
  );

  const toOnChange = useCallback(
    (value: string) => {
      if (language !== value) return onChangeLanguage(value);
    },
    [language, onChangeLanguage],
  );

  return (
    <Select
      fullWidth={fullWidth}
      items={items}
      size='large'
      variant='secondary'
      value={currentValue}
      className={classSelect}
      classNameInput={classNames(cls['select-input'], {}, [className])}
      onChange={value => toOnChange(value)}
      {...otherProps}
    />
  );
});
